<template>
  <EndTestBackground :testType="1">
    <div>
      <span class="green">元昊</span> 同学，恭喜你完成
      <span class="green">中考英语冲刺扫雷尖兵效果测评</span>
    </div>
    <div>
      
        本次词汇部分的体检得分：<span class="green">115分</span>
        </div>
    <div>
      获得 测评答题 <pybWord></pybWord> 关卡奖励
      <pybWord></pybWord> ，已纳入你的后勤补给！
    </div>
    <div> 
      <span class="green">“语法与功能”</span>的靶向训练已自动解锁，欢迎挑战
    </div>
    <GlobalButton
      buttonType="round"
      buttonText="继续闯关"
      @afterClick="continueSprint"
    ></GlobalButton>
  </EndTestBackground>
</template>

<script>
import GlobalButton from "@/components/global-button/global-button-enter.vue";
import pybWord from "@/components/key-words/pyb-key-word.vue";
import EndTestBackground from "@/components/backgroung/end-test-background.vue";
export default {
  components: {
    GlobalButton,
    pybWord,
    EndTestBackground,
  },
  methods: {
    continueSprint() {
      this.$router.replace({
        name: "SmallModuleGrammarList",
        query: {
          // module: 1,
        },
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.green {
  color: #277b24;
}
</style>